// Vvonsole 初始化
import Vconsole from 'vconsole'
if (process.env.VUE_APP_IS_OPEN_CONSOLE == 'true') {
  new Vconsole()
}

// Fundebug 初始化
if (['prod', 'uat'].includes(process.env.VUE_APP_ENV)) {
  const fundebug = require('./fundebug')
  fundebug.default.run()
}

import { initApp } from './utils/appInit'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Api from './api/api'
import i18n from './i18n/i18n'
import 'amfe-flexible/index.js'
import './utils/global'
import { Session } from '@/utils/storage'

import * as filters from './filters' // global filters

import Directive from '@/directive'
Vue.use(Directive)

require('./assets/css/customFontType.css')
require('./assets/css/reset.css')
require('./assets/css/common.scss')
require('./assets/css/theme.scss')

Vue.prototype.$api = Api

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.config.productionTip = false

function getPageTitle(i18n, key) {
  const hasKey = i18n.te(`meta.${key}`)
  const title = key
  if (hasKey) {
    const pageName = i18n.t(`meta.${key}`)
    return `${pageName}`
  }
  return `${title}`
}

router.beforeEach((to, from, next) => {
  const appInfo = Session.get('appInfo') || {}
  const agentUid = store.state.base.agentUid || appInfo.agentUid || ''
  /* 路由发生变化修改页面title */
  if ((to.meta.title || to.name) && agentUid) {
    document.title = getPageTitle(i18n, to.meta.title)
  }
  next()
})

const initialApp = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
})

// 初始化 获取app的token和基本配置 initApp可以修改本地开发环境的 appInfo
// 异步通信 callback 回调 token 以后再挂载 APP
initApp(() => initialApp.$mount('#app'))

