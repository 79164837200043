import request from '@/utils/request2.js'
import dao from '../utils/dao'

const PostMethod = (data) => ({ method: 'post', data })

// For those using alternative tokens
function returnHeader() {
  return {
    'x-5a-temp-token': window.location.href.includes('claim') ? dao.get('tempToken') : dao.get('inspectionToken')
  }
}

function determineToken() {
  const haveInspectionToken = dao.get('inspectionToken') || false
  return haveInspectionToken
}

// 获取文件URL
export function getFileUrl(query) {
  if (window.location.href.includes('claim')) {
    return request({
      url: '/claim/temp/store/generate/url',
      method: 'post',
      data: query,
      headers: returnHeader()
    })
  }
  if (determineToken()) {
    return request({
      url: '/local/inspection/store/generate/url',
      method: 'post',
      data: query,
      headers: returnHeader()
    })
  } else {
    return request({
      url: '/pro/document/store/generate/url',
      method: 'post',
      data: query
    })
  }
}

// 获取文件URL V2 支持添加水印功能
export function getFileUrlV2(query) {
  if (window.location.href.includes('claim')) {
    return request({
      url: '/claim/temp/store/generate/url/v2',
      method: 'post',
      data: query,
      headers: returnHeader()
    })
  }
  if (determineToken()) {
    return request({
      url: '/local/inspection/store/generate/url/v2',
      method: 'post',
      data: query,
      headers: returnHeader()
    })
  } else {
    return request({
      url: '/pro/document/store/generate/url/v2',
      method: 'post',
      data: query
    })
  }
}

// 获取订单信息
export function getOrderInfo(query) {
  return request({
    url: '/pro/order/basic/info',
    method: 'post',
    data: query
  })
}

// 获取上传文件的URL
export function getUploadUrl(query) {
  return request({
    url: '/pro/document/store/upload/getUrl',
    method: 'post',
    data: query
  })
}

// h获取营销产品详情
export function getProductDetail(query) {
  return request({
    url: '/pro/proDetail',
    method: 'post',
    data: query
  })
}

// 泰国 API 报价
export function getThaiPlanList(data) {
  return request({
    url: '/pro/quote/forecast/list',
    method: 'post',
    data
  })
}

// 获取 plan logo
export function getCompLogo(data) {
  return request({
    url: '/pro/proPicture',
    method: 'post',
    data
  })
}

// 泰国 重新计价
export function queryQuotePrice(data) {
  return request({
    url: '/pro/quote/api/price',
    method: 'post',
    data
  })
}

// 泰国 购买强制险
export function queryOtherPlan(data) {
  return request({
    url: '/pro/load/otherPlan',
    method: 'post',
    data
  })
}

export function getQuotationPrice(query) {
  return request({
    url: '/pro/order/quotation/price',
    method: 'post',
    data: query
  })
}

export function queryCoveragesPreQuotation(data) {
  return request({
    url: '/pro/order/preQuotation/price',
    ...PostMethod(data)
  })
}

// 原料产品计划明细
export function getProductRawPlan(query) {
  return request({
    url: '/pro/proRawPlan',
    method: 'post',
    data: query
  })
}

// 营销产品计划详情
export function getProductPlanDetail(query) {
  return request({
    url: '/pro/proPlanDetail',
    method: 'post',
    data: query
  })
}

// 营销产品表单
export function getProForm(query) {
  return request({
    url: '/pro/proForm',
    method: 'post',
    data: query
  })
}

// 检查是否支持 pay later
export function checkCanPayLater(query) {
  return request({
    url: '/pro/order/v2/th/canPayLater',
    method: 'post',
    data: query
  })
}

// 获取显示分类详情信息
export function getProductDisplayCategory(query) {
  return request({
    url: '/pro/displayCategory/get',
    method: 'post',
    data: query
  })
}

// TH 产品比价接口 comparePrice
export function getComparePrice(query) {
  return request({
    url: '/pro/order/preQuotation/comparePrice',
    method: 'post',
    data: query
  })
}

// 创建订单
export function createOrder(query) {
  return request({
    url: '/pro/order/create',
    method: 'post',
    data: query
  })
}

// 查询订单基础信息
export function getOrderBasicInfo(query) {
  return request({
    url: '/pro/order/basic/info',
    method: 'post',
    data: query
  })
}

// 加载订单信息v2
export function queryV2OrderInfo(data) {
  return request({
    url: '/pro/order/v2/orderInfo',
    method: 'post',
    data
  })
}

// 加载订单下投保单集合 v2
export function queryV2SlipLs(data) {
  return request({
    url: '/pro/order/v2/slip/slipLs',
    method: 'post',
    data
  })
}

// 加载订单下 peyment 相关的 信息 (THAI)
export function queryV2SlipPaymentInfo(data) {
  return request({
    url: '/pro/order/v2/slip/paymentInfo',
    method: 'post',
    data
  })
}

// 保单详情 coverage list 相关 (THAI)
export function queryCalculateProduct(data) {
  return request({
    url: '/pro/order/v2/slip/quote/record',
    method: 'post',
    data
  })
}

// 加载投保单 表单信息 v2
export function queryV2ApplicationInfo(data) {
  return request({
    url: '/pro/order/v2/slip/application/info',
    method: 'post',
    data
  })
}

// 加载投保单详情 v2
export function queryV2SlipInfo(data) {
  return request({
    url: '/pro/order/v2/slip/info',
    method: 'post',
    data
  })
}

// 查询订单字段信息
export function getOrderFieldInfo(query) {
  return request({
    url: '/pro/order/field/info',
    method: 'post',
    data: query
  })
}

// partner是否进行支付接口
export function partnerOrderPaymentTask(query) {
  return request({
    url: '/pro/payment/partnerOrderPaymentTask',
    method: 'post',
    data: query
  })
}

// partner是否进行支付接口 V2
export function partnerOrderPaymentTaskV2(query) {
  return request({
    url: '/pro/payment/partnerOrderPaymentTaskV2',
    method: 'post',
    data: query
  })
}

// 产品详情
export function getProDetail(query) {
  return request({
    url: '/pro/product/detail',
    method: 'post',
    data: query
  })
}

// 加载指定原料产品方案Benefit Detail文本内容
export function getProductBenefitDetail(query) {
  return request({
    url: '/pro/proProductBeneftDetail',
    method: 'post',
    data: query
  })
}

// Binder产品投保
export function createBinderOrder(query) {
  return request({
    url: '/pro/order/createBinderOrder',
    method: 'post',
    data: query
  })
}

// 获取可支付对象 v2 用于进收银台复制接口复制文档复制地址
export function getPaymentTarget(query) {
  return request({
    url: '/pro/order/v2/getPaymentTarget',
    method: 'post',
    data: query
  })
}

// 根据订单查询支付记录
export function queryOrderPayRecord(query) {
  return request({
    url: '/pro/payment/orderPayRecord/query',
    method: 'post',
    data: query
  })
}

// 保存标准询价单
export function saveBinderRfqOrder(query) {
  return request({
    url: '/pro/rfq/order/saveBinderRfqOrder',
    method: 'post',
    data: query
  })
}

// 签署合同
export function partnershipPreview(query) {
  return request({
    url: '/pro/prm/partner/contract/preview',
    method: 'post',
    data: query
  })
}

// inspection 信息录入
export function appendInspection(query) {
  return request({
    url: '/pro/slip/inspection/appendInspection',
    method: 'post',
    data: query
  })
}

// 获取 inspection 模版详情
export function queryInspectionInfo(query) {
  return request({
    url: '/pro/inspection/template/info',
    method: 'post',
    data: query
  })
}

// open or close netPayment
export function enableNetPayment(query) {
  return request({
    url: '/pro/order/enable/netPayment',
    method: 'post',
    data: query
  })
}

// 询价单详情信息
export function getrfpOrderDetails(query) {
  return request({
    url: '/pro/rfq/order/details',
    method: 'post',
    data: query
  })
}

// 报价单拒绝
export function quoteDecline(query) {
  return request({
    url: '/pro/rfq/order/quote/decline',
    method: 'post',
    data: query
  })
}

// 报价单费用信息
export function getRfpOrderQuoteFee(query) {
  return request({
    url: '/pro/rfq/order/quote/fee',
    method: 'post',
    data: query
  })
}

// async compare price
export function asyncComparePrice(data) {
  return request({
    url: '/pro/order/quote/asyncComparePrice',
    method: 'post',
    data
  })
}

// 算价接口优化版本
export function asyncComparePriceV2(data) {
  return request({
    url: '/pro/qes/sdk/quote/asyncComparePrice',
    method: 'post',
    data
  })
}

// async compare price
export function asyncQueryPrice(data, signal) {
  return request({
    url: '/pro/order/quote/getPrice',
    method: 'post',
    data,
    signal
  })
}

// async compare price
export function queryCompNameLocal(data, signal) {
  return request({
    url: '/pro/queryInsCoByIds',
    method: 'post',
    data
  })
}

// 泰国 保单先去获取 E-Policy
export function queryEPolicy(data, signal) {
  return request({
    url: '/pro/order/v2/slip/getEpolicyList',
    method: 'post',
    data
  })
}

// 查询 displayCategory 详情
export function queryDisplayCategory(data, signal) {
  return request({
    url: '/pro/displayCategory/get',
    method: 'post',
    data
  })
}

// 新核心逻辑的开关
export function switchNewCoreLogic(data, signal) {
  return request({
    url: '/local/config/switchNewCoreLogic',
    method: 'post',
    data
  })
}

// 提成预计算接口
export function preQuotationCommission(data, signal) {
  return request({
    url: '/pro/order/preQuotation/commission',
    method: 'post',
    data
  })
}

// Downlines Management获取基础信息
export function performanceBasic(data, signal) {
  return request({
    url: '/local/partner/performance/basic',
    method: 'post',
    data
  })
}

// Downlines Management获取直接下线
export function performanceDownlines(data, signal) {
  return request({
    url: '/local/partner/performance/downlines',
    method: 'post',
    data
  })
}

// Downlines Management获取 myTerm
export function performanceMyTerm(data, signal) {
  return request({
    url: '/local/partner/performance/report/myTerm',
    method: 'post',
    data
  })
}

// Downlines Management获取 myself
export function performanceMyself(data, signal) {
  return request({
    url: '/local/partner/performance/report/myself',
    method: 'post',
    data
  })
}

// 提供视频水印任务触发接口
export function triggerAttachmentTask(data, signal) {
  if (window.location.href.includes('claim')) {
    return request({
      url: '/claim/temp/store/attachmentTask/trigger',
      method: 'post',
      data: data,
      headers: returnHeader()
    })
  }
  if (determineToken()) {
    return request({
      url: '/local/inspection/store/attachmentTask/trigger',
      method: 'post',
      headers: returnHeader(),
      data: data
    })
  } else {
    return request({
      url: '/pro/oss/attachmentTask/trigger',
      method: 'post',
      data
    })
  }
}

export function inspectionVehicleValidate(data, signal) {
  if (window.location.href.includes('claim')) {
    return request({
      url: '/local/inspection/live-photo/validate',
      method: 'post',
      data: data,
      headers: returnHeader()
    })
  }
  if (determineToken()) {
    return request({
      url: '/local/inspection/live-photo/validate',
      method: 'post',
      headers: returnHeader(),
      data: data
    })
  } else {
    return request({
      url: '/local/inspection/live-photo/validate',
      method: 'post',
      data
    })
  }
}

// live photo查询品牌
export function checkBrand(data) {
  if (window.location.href.includes('claim')) {
    return request({
      url: '/local/inspection/live-photo/check/brand',
      method: 'post',
      data: data,
      headers: returnHeader()
    })
  }
  if (determineToken()) {
    return request({
      url: '/local/inspection/live-photo/check/brand',
      method: 'post',
      headers: returnHeader(),
      data: data
    })
  } else {
    return request({
      url: '/local/inspection/live-photo/check/brand',
      method: 'post',
      data
    })
  }
}

// 查询pro配置
// 目前查询的是否有通融功能
export function queryProConfig(data) {
  return request({
    url: '/pro/proConfig',
    method: 'post',
    data
  })
}

// 提交通融
export function submitNegotiation(data) {
  return request({
    url: '/pro/quote/negotiation/negotiate',
    method: 'post',
    data
  })
}

// negotiation 提交通融(新)
export function submitNewNegotiation(query) {
  return request({
    url: '/pro/iqs/rfq/negotiation/submit',
    method: 'post',
    data: query
  })
}

export function getComparisonList(query) {
  return request({
    url: '/vn/pro/comparisons/list',
    method: 'post',
    data: query
  })
}

export function saveComparison(query) {
  return request({
    url: '/vn/pro/comparisons/saveComparison',
    method: 'post',
    data: query
  })
}

export function getComparisonDetail(query) {
  return request({
    url: '/vn/pro/comparisons/detail',
    method: 'post',
    data: query
  })
}

export function getComparisonDetailQuote(query) {
  return request({
    url: '/vn/pro/rfq/order/quote/detail',
    method: 'post',
    data: query
  })
}

export function getComparisonDetailGroup(query) {
  return request({
    url: '/vn/pro/standard/list',
    method: 'post',
    data: query
  })
}

export function updateComparisonCoverage(query) {
  return request({
    url: '/vn/pro/comparisons/updateComparison',
    method: 'post',
    data: query
  })
}

// 越南 - Coupon 查询可用Coupon
export function queryAvailable(query) {
  return request({
    url: '/vn/local/mcs/coupon/redeem/query/available',
    method: 'post',
    data: query
  })
}

// 越南 - Coupon 查询Reward
export function couponReward(query) {
  return request({
    url: '/vn/local/mcs/coupon/redeem/query/coupon/reward',
    method: 'post',
    data: query
  })
}

// 下单时根据报价单号获取所需inspection信息
export function getProductInspectionMsg(query) {
  return request({
    url: '/pro/slip/inspection/getProductInspectionMsg',
    method: 'post',
    data: query
  })
}

// 生成token让customer完成inspection
export function generateShareToken(query) {
  return request({
    url: '/local/inspection/generate/share/token',
    method: 'post',
    data: query
  })
}

// 非标准产品提交rfq /pro/iqs/rfq/submit
export function postRfqSubmit(query) {
  return request({
    url: '/pro/iqs/rfq/submit',
    method: 'post',
    data: query
  })
}

// thai 通融submit
export function posNegotiationSubmit(query) {
  return request({
    url: '/pro/iqs/rfq/negotiation/submit',
    method: 'post',
    data: query
  })
}

// thai rfq details
export function postRfqDetails(query) {
  return request({
    url: '/pro/iqs/rfq/detail',
    method: 'post',
    data: query
  })
}

// thai qs list
export function postQsList(query) {
  return request({
    url: '/pro/iqs/qs/list',
    method: 'post',
    data: query
  })
}

// thai qs id Details '/pro/iqs/qs/detail'
export function postQsDetails(query) {
  return request({
    url: '/pro/iqs/qs/detail',
    method: 'post',
    data: query
  })
}

// thai rfq application form  /core/FormTemplateInfo/info
export function postRfqApplicationForm(query) {
  return request({
    url: '/pro/pfm/form/FormTemplateInfo/info',
    method: 'post',
    data: query
  })
}

// thai rfq application form  v2
export function postRfqApplicationFormV2(query) {
  return request({
    url: '/pro/iqs/qs/queryForOrder',
    method: 'post',
    data: query
  })
}

// thai reason list ops {type: 'QS_DECLINE'}
export function postRfqReasonOps(query = {}) {
  return request({
    url: '/pro/dictionary/reason',
    method: 'post',
    data: { ...query, type: 'QS_DECLINE' }
  })
}

// thai rqs decline submit /pro/iqs/qs/decline
export function postRfqReasonSubmit(query = {}) {
  return request({
    url: '/pro/iqs/qs/decline',
    method: 'post',
    data: { ...query, type: 'QS_DECLINE' }
  })
}

// thai appliction form submit
export function postRfqApplicationSubmit(query = {}) {
  return request({
    url: '/pro/order/createNonBinderOrder',
    method: 'post',
    data: query
  })
}

// 查询是否需要require more data  /pro/iqs/additionForm/query/doing
export function postIfNeedRequireMoreData(query = {}) {
  return request({
    url: '/pro/iqs/additionForm/query/doing',
    method: 'post',
    data: query
  })
}

// thai require more data submit
export function postRequireMoreSubmit(query = {}) {
  return request({
    url: '/pro/iqs/additionForm/reply',
    method: 'post',
    data: query
  })
}

// 泰国 查询可用coupon
export function getCouponList(query) {
  return request({
    url: '/pro/mcs/coupon/redeem/query/slip/available',
    method: 'post',
    data: query
  })
}

// negotiation 新旧交替
export function newNegotiationSwitch(query) {
  return request({
    url: '/local/config/supportNewNegotiation',
    method: 'post',
    data: query
  })
}

// non-binder 查询合拼需要的need more data
export function getNeedMoreDataInfo(query) {
  return request({
    url: '/pro/iqs/additionForm/query/all',
    method: 'post',
    data: query
  })
}

// 新版收银台 创建缴费单接口
export function createBilling(query) {
  return request({
    url: '/pro/paymentBilling/create',
    method: 'post',
    data: query
  })
}

// 根据订单号查询缴费单列表
export function queryBillingLs(query) {
  return request({
    url: '/pro/paymentBilling/list/byOrder',
    method: 'post',
    data: query
  })
}

// 获取投保单清算信息
export function querySlipClearingInfo(query) {
  return request({
    url: '/pro/clearing/slip/getInfo',
    method: 'post',
    data: query
  })
}
