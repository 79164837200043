/*
 * @Author       : Simba
 * @Date         : 2021-11-22 11:38:04
 * @LastEditors  : Simba
 * @LastEditTime : 2022-03-02 15:14:59
 * @Description  : message
 * @FilePath     : /vn-register/src/router/index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import { dispatchAction } from '@/utils/dispatch'
import store from '@/store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style

// 右上角不显示loading图标
NProgress.configure({ showSpinner: false })

Vue.use(VueRouter)

const routes = [
  {
    path: '/inspection/orderDetail',
    component: () => import('@/views/inspectionCustomer/orderDetail.vue'),
    name: 'OrderDetail',
    meta: { title: 'OrderDetail', icon: 'dashboard' },
    isSkipPermission: true
  },
  {
    path: '/inspection/inspectionForm',
    component: () => import('@/views/inspectionCustomer/index.vue'),
    name: 'InspectionForm',
    meta: { title: 'InspectionForm', icon: 'dashboard' },
    isSkipPermission: true
  },
  {
    path: '/inspection/linkExpired',
    component: () => import('@/views/inspectionCustomer/linkExpired.vue'),
    name: 'LinkExpired',
    meta: { title: 'LinkExpired', icon: 'dashboard' },
    isSkipPermission: true
  },
  {
    path: '/inspection/verification',
    component: () => import('@/views/inspectionCustomer/verification.vue'),
    name: 'Verification',
    meta: { title: 'Verification', icon: 'dashboard' },
    isSkipPermission: true
  },
  {
    path: '/inspection/completed',
    component: () => import('@/views/inspectionCustomer/completed.vue'),
    name: 'Completed',
    meta: { title: 'Completed', icon: 'dashboard' },
    isSkipPermission: true
  },
  {
    path: '/claim/inspectionForm',
    component: () => import('@/views/claim/inspection.vue'),
    name: 'ClaimInspectionForm',
    meta: { title: 'ClaimForm', icon: 'dashboard' },
    isSkipPermission: true
  },
  {
    path: '/claim/claimForm',
    component: () => import('@/views/claim/index.vue'),
    name: 'ClaimForm',
    meta: { title: 'ClaimForm', icon: 'dashboard' },
    isSkipPermission: true
  },
  {
    path: '/claim/createReport',
    name: 'ClaimReport',
    component: () => import('@/views/claim/report/index.vue'),
    meta: { title: 'CreateReport' },
    isSkipPermission: true
  },
  {
    path: '/claim/portal',
    name: 'EbClaimPortal',
    component: () => import('@/views/claim/eb/portal/index.vue'),
    meta: {
      title: 'Claim Portal'
    },
    hidden: true,
    isSkipPermission: true
  },
  {
    path: '/claim/submit/form',
    name: 'EbSubmitClaim',
    component: () => import('@/views/claim/eb/submit/form/index.vue'),
    meta: {
      title: 'Submit Claim'
    },
    hidden: true,
    isSkipPermission: true
  },
  {
    path: '/claim/submit/verification',
    name: 'VerifyCode',
    component: () => import('@/views/claim/eb/submit/verification/index.vue'),
    meta: {
      title: 'VerifyCode'
    },
    hidden: true,
    isSkipPermission: true
  },
  {
    path: '/claim/submit/success',
    name: 'EbClaimSubmitSuccess',
    component: () => import('@/views/claim/eb/success/index.vue'),
    meta: {
      title: 'Submit Success'
    },
    hidden: true,
    isSkipPermission: true
  },
  {
    path: '/claim/track',
    name: 'EbTrackClaim',
    component: () => import('@/views/claim/eb/track/start/index.vue'),
    meta: {
      title: 'Track Claim'
    },
    hidden: true,
    isSkipPermission: true
  },
  {
    path: '/claim/track/list',
    name: 'EbTrackClaimList',
    component: () => import('@/views/claim/eb/track/list/index.vue'),
    meta: {
      title: 'Claim List'
    },
    hidden: true,
    isSkipPermission: true
  },
  {
    path: '/claim/track/form',
    name: 'EbTrackForm',
    component: () => import('@/views/claim/eb/track/form/index.vue'),
    meta: {
      title: 'Form'
    },
    hidden: true,
    isSkipPermission: true
  },
  {
    path: '/claim/track/claim/detail',
    name: 'EbTrackClaimDetail',
    component: () => import('@/views/claim/eb/track/claimDetail/index.vue'),
    meta: {
      title: 'Claim Detail'
    },
    hidden: true,
    isSkipPermission: true
  },
  {
    path: '/claim/track/report/detail',
    name: 'EbTrackReportDetail',
    component: () => import('@/views/claim/eb/track/reportDetail/index.vue'),
    meta: {
      title: 'Report Detail'
    },
    hidden: true,
    isSkipPermission: true
  },
  {
    path: '/claim/track/fail',
    name: 'EbClaimSubmitFail',
    component: () => import('@/views/claim/eb/fail/index.vue'),
    meta: {
      title: 'Submit Fail'
    },
    hidden: true,
    isSkipPermission: true
  },
  {
    path: '/claim/guidance',
    name: 'GuidancePage',
    component: () => import('@/views/claim/eb/guidance/index.vue'),
    meta: {
      title: 'Guidance'
    },
    hidden: true,
    isSkipPermission: true
  },
  {
    path: '/temp/refinancing/review',
    name: 'refinancingReview',
    component: () => import('@/views/refinancing/index.vue'),
    meta: {
      title: 'Refinancing Review'
    },
    hidden: true,
    isSkipPermission: true
  },
  {
    path: '/temp/refinancing/invalid',
    name: 'refinancingInvalid',
    component: () => import('@/views/refinancing/invalid.vue'),
    meta: {
      title: 'Refinancing Invalid'
    },
    hidden: true,
    isSkipPermission: true
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  NProgress.start()
  store.dispatch('page/changeRouterLoading', true)

  next()
})

router.afterEach(async (to, from, next) => {
  NProgress.done()
  store.dispatch('page/changeRouterLoading', false)

  // refresh for CSS fix
  if ((from.name == 'InspectionForm' || from.name == 'Verification') && to.name == 'OrderDetail') {
    location.reload()
  }

  // 每次路由调转后都把原生点击回退的方法重写成默认模式
  dispatchAction({
    method: 'onNativeBack',
    fixedName: true,
    communication: true, // 不直接通信
    cbType: 2,
    callback: () => {
      // 默认H5回退模式
      dispatchAction({
        method: 'backNativePage',
        params: {}
      })
    }
  })
})

export default router
