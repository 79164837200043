import Vue from 'vue'
import { Toast, Notify } from 'vant'
import '@vant/touch-emulator'
// 全局注册
Vue.use(Notify)
Vue.use(Toast)

// 添加全局方法
Vue.prototype.loading = function () {
  Toast.loading({
    duration: 0,
    forbidClick: true,
    loadingType: 'spinner'
  })
}

Vue.prototype.clear = function () {
  Toast.clear()
}

Vue.prototype.notifyDanger = function (mess) {
  Notify({
    message: mess,
    type: 'danger'
  })
}

Vue.prototype.notifySuccess = function (mess) {
  Notify({
    message: mess,
    type: 'success'
  })
}
