/**
 * @Author:       huangjiaquan
 * @Date:         2023-06-12 14:45
 * @LastEditTime: 2023-06-12 14:45
 * @LastEditors:  huangjiaquan
 * @FilePath:     scm-web/src/views/test/utils
 */
// import { Session } from '@/utils/storage'
// const appInfo = Session.get('appInfo') || {}
// const countrySetting = {
//   thousands: ['ID'].includes(appInfo.country) ? ['.', ','] : [',', '.']
// }

const countrySetting = {
  thousands: [',', '.']
}

/**
 * @name inputFormatter
 * @msg: el-input格式化自定义指令
 * @param {Function} formatter 格式化函数
 * @param {Function} parser 解析函数
 * @param {Function} limit 输入限制函数
 * @param {Boolean} watchInput 是否监听input事件格式化显示
 * @param {Boolean} isNumber 是否为数字类型输入框（小数）
 * @returns 返回自定义指令生命周期函数
 */
export const inputFormatter = (
  {
    formatter = (e) => e,
    parser = (e) => e,
    limit = (e) => e,
    watchInput = true,
    isNumber = false,
    type = ''
  }
) => {
  return (el, binding, vnode) => {
    const precision = vnode?.child?.$attrs?.precision || binding?.value?.precision || '0'
    const defaultValue = `${vnode?.child?.$attrs['default-value']}` || `${binding?.value?.defaultValue}` || ''
    const min = vnode?.child?.$attrs?.min + '' || binding?.value?.min + '' || ''
    const max = vnode?.child?.$attrs?.max + '' || binding?.value?.max + '' || 9007199254740991
    let watchVal = true
    // 存旧值
    let oldInputVal = null
    // 判断是否格式
    let isDelayedFormatting = false

    const input = el.getElementsByTagName('input')[0]
    // 获取记录光标位置
    let selectionSite
    const getSelectionSite = (event) => {
      const oldVal = event.target.value || ''
      const selectionStart = event.target.selectionStart

      vnode.context.$nextTick(() => {
        if ((selectionStart === selectionSite || selectionSite - 1 === oldVal.length) && event.type === 'keyup') {
          input.selectionStart = selectionSite
          input.selectionEnd = selectionSite
          selectionSite = null
        }

        if (typeof selectionSite === 'number' && event.type === 'click') {
          input.selectionStart = selectionSite
          input.selectionEnd = selectionSite
          selectionSite = null
        }
      })
    }
    // 点击、键盘事件更新光标位置
    input.addEventListener('click', getSelectionSite, false)
    input.addEventListener('keyup', getSelectionSite, false)

    // 为input绑定值赋值
    const assignment = (val) => {
      let value = parser(val, countrySetting, binding)
      if (countrySetting.thousands[1] === ',' && (value.toString()).includes(',')) {
        value = value.replace(',', '.')
      }
      if (!isNaN(Number(value)) && isNumber && value !== '') {
        value = conditionalFiltering(value)
      }

      vnode.componentInstance.$emit('input', value)
    }

    const conditionalFiltering = (val) => {
      if (type && type === 'thousands') {
        if (val === '-') {
          return val
        }
        if (Number(val)) {
          const num = val
          if (min && Number(min) > Number(num)) {
            return Number(min)
          }
          if (max && Number(max) < Number(num)) {
            return Number(max) > 9007199254740991 ? 9007199254740991 : Number(max)
          }
          return Number(val)
        }
      }
      return val
    }

    // 更改显示的值
    const upShow = (val, type) => {
      oldInputVal = val

      vnode.context.$nextTick(() => {
        input.value = val
      })
    }
    // 监听绑定值变化
    vnode.componentInstance.$watch('value', (val, old) => {
      let newValue = val + ''
      if (newValue.includes('.') && countrySetting.thousands[1] === ',') {
        newValue = newValue.replace('.', ',')
      }
      newValue = conditionalFiltering(newValue)
      if (watchVal) {
        upShow(formatter(newValue, countrySetting, binding), '$watch')
      }
    }, { deep: true, immediate: true })

    const step_1_reg = new RegExp(`[^\\d^\\${countrySetting.thousands[1]}^\\-]+`, 'g')
    const step_3_reg = new RegExp(`^\\${countrySetting.thousands[1]}`)
    const step_3_1_reg = new RegExp(`^\\-${countrySetting.thousands[1]}`)
    const step_4_reg = new RegExp(`\\${countrySetting.thousands[1]}`, 'g')
    // 小数点位数过滤
    const precisionReg = new RegExp(`^(\\-)?\\d*(\\${countrySetting.thousands[1]}?\\d{0,${precision}})`, 'g')
    // 数字格式化
    const toNumber = (val) => {
      val = val.toString()
        .replace(step_1_reg, '') // 第二步：把不是数字，不是小数点、-的过滤掉
        .replace(/^0+(\d)/, '$1') // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
        .replace(step_3_reg, `0${countrySetting.thousands[1]}`) // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
        .replace(step_3_1_reg, `-0${countrySetting.thousands[1]}`) // 第四步：如果输入的第一位为负号小数点，则替换成 -0. 实现自动补全
        .replace(`${countrySetting.thousands[1]}`, '$#$')
        .replace(step_4_reg, '')
        .replace('$#$', `${countrySetting.thousands[1]}`) // 只保留第一个".", 清除多余的"."
        .match(precisionReg)[0] || '' // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有1到n位小数

      return val
    }
    // 处理最后一位非法字符
    const handlerIllegalStr = (str) => {
      const reg = new RegExp(`^[0-9]+${countrySetting.thousands[1]}?[0-9]*`)

      while (!(reg.test(str.charAt(str.length - 1))) && str) {
        str = str.substr(0, str.length - 1)
      }
      return str || ''
    }
    // 监听input事件，可添加操作
    input.addEventListener('input', (event) => {
      event.stopPropagation()
      const selectionEnd = input.selectionEnd
      const selectionStart = input.selectionStart
      input.selectionEnd = selectionStart

      let val = event.target.value

      if (type && type === 'thousands') {
        // 把按键‘.’ 换成对应配置的小数点
        if (['.', '。'].includes(val.substr(-1))) {
          val = val.slice(0, -1) + countrySetting.thousands[1]
        }

        // 当没有小数位时，不允许出现小数点
        if (precision == '0' && ['.', '，', '。', ','].includes(val.substr(-1))) {
          val = val.slice(0, -1)
        }

        if (min && min == 0 && ['-'].includes(val)) {
          val = 0
        }

        // 粘贴进来需要处理
        if (event.inputType === 'insertFromPaste') {
          val = val.replaceAll(' ', '')
          // 如果小数点是','的,需要把'.'==>','
          if (countrySetting.thousands[1] === ',' && val.includes('.')) {
            val = val.replace('.', ',')
          }
        }
      }

      // 判断时候是删除逗号
      const newVal = val // 保存删除逗号的数据
      isDelayedFormatting = oldInputVal.split(',').length > val.split(',').length

      if (binding.modifiers.number || isNumber) {
        val = toNumber(val)
      }

      const inp = limit(val)
      event.target.value = inp
      if (binding.modifiers.watchInput || watchInput) {
        assignment(inp)

        if (isDelayedFormatting) {
          oldInputVal = val
          upShow(newVal, 'input')
        } else {
          upShow(formatter(conditionalFiltering(val), countrySetting, binding), 'input')
        }
      }

      vnode.context.$nextTick(() => {
        if (input.value.length != selectionStart) {
          const newStart = oldInputVal.length - selectionEnd
          const offset = oldInputVal.split(',').length - newVal.split(',').length
          let index = input.value.length - newStart + offset
          index = index < 0 ? 0 : index
          selectionSite = index
          input.selectionStart = index
          input.selectionEnd = index
        }
      })
    }, false)
    if (input) {
      input.addEventListener('blur', (event) => {
        event.stopPropagation()
        watchVal = true
        let val = event.target.value
        if (binding.modifiers.number || isNumber) {
          val = handlerIllegalStr(val)
        }

        if (val === '' && defaultValue !== '' && !isNaN(defaultValue)) {
          val = defaultValue
        }

        // 处理无法删除逗号问题
        if (isDelayedFormatting) {
          upShow(formatter(conditionalFiltering(val.replaceAll(',', '')), countrySetting, binding), 'blur')
        }
        assignment(val)
        selectionSite = null
      }, false)
      input.addEventListener('focus', (event) => {
        event.stopPropagation()
        // const val = event.target.value
        // input.addEventListener('mousemove', e => {
        //   const oldVal = e.target.value || ''
        //   const selectionStart = e.target.selectionStart
        //   selectionSite = oldVal.length - selectionStart
        //   console.log(selectionSite)
        // }, false)

        // event.target.setSelectionRange(0, val.length)
        const val = event.target.value
        upShow(formatter(conditionalFiltering(val.replaceAll(',', '')), countrySetting, binding), 'focus')

        watchVal = false
      }, false)
    }
  }
}
