import { setRandomKey } from './index'
import { urlToObj } from '@/utils'

// 桌面端标识
const DESKTOP_WIND_TAG = 'wind_pc'
const DESKTOP_MAC_TAG = 'mac_pc'
const DESKTOP_TAG = [DESKTOP_WIND_TAG, DESKTOP_MAC_TAG, 'lin_pc', 'fuc_pc']
// h5端标识
const WEB_TAG = ['web']
// 获取路由参数
const urlParams = urlToObj()
// 目前 h5 的通信终端有三种情况
// 约定通过路由上的 tag 参数来区分不同终端
const tag = urlParams.tag
// 终端1 ==> 原生 flutter (tag为空)
// 终端2 ==> flutter 打包的桌面端 (tag: wind_pc/mac_pc/lin_pc/fuc_pc)
// 终端3 ==> flutter 打包的h5包 (tag: web)

// 是否是在原生的 flutter 应用中
const isApp = !!window.flutter_inappwebview
// 是否是桌面端
export const isDesktop = DESKTOP_TAG.includes(tag)
const isMac = tag == DESKTOP_MAC_TAG
// 是否是flutter打包的h5中
export const isWeb = WEB_TAG.includes(tag)

// 是否是在flutter或flutter打包的终端中使用
export const isInApp = isApp || isDesktop || isWeb

// 固定的回调方法
const FIXED_CALLBACK = {
  onNativeBack() {
    dispatchAction({ method: 'backNativePage', params: {}})
  }
}

// 配置的全局回调
window.onFusePro = {
  // 原生头部点击回退的响应事件
  onNativeBack: FIXED_CALLBACK.onNativeBack
}

// 通过flutter原生方式通信
function dispatchActionByFlutter({
  type = 'FusePro',
  params = {},
  callback,
  callbackName,
  cbType = 1 // 0 一直存在，不做修改 ; 1 只会调用一次，执行完删除 ; 2 调用一次，执行完会赋空方法
}) {
  console.info('tip: 进入 flutter原生通信 方式')
  const { method } = params

  // 一种兼容处理
  if (window.flutter_inappwebview.callHandler) {
    const message = JSON.stringify({
      ...params,
      callbackName
    })

    // 判断是否有回调方法
    if (callback && typeof callback == 'function') {
      // 配置全局回调
      setWindowCallback(callbackName, callback, cbType)
    }
    window.flutter_inappwebview.callHandler(type, message).then(result => {
      if (callback && typeof callback == 'function') {
        if (method != 'getPhoto' && method != 'canShare') {
          callback(typeof result == 'string' ? JSON.parse(result) : result)
        }
      }
    })
  } else {
    const message = JSON.stringify({
      ...params,
      callbackName
    })

    // 判断是否有回调方法
    if (callback && typeof callback == 'function') {
      // 配置全局回调
      setWindowCallback(callbackName, callback, cbType)
    }

    // 区分终端
    window.flutter_inappwebview._callHandler(type, setTimeout(() => {}), JSON.stringify([message]))
  }
}

// 通过路由拦截的方式通信
// 目前是 桌面端 win 版本在使用
function dispatchActionByRouteInterception({
  params = {},
  callback,
  callbackName,
  cbType = 1 // 0 一直存在，不做修改 ; 1 只会调用一次，执行完删除 ; 2 调用一次，执行完会赋空方法
}) {
  console.info('tip: 进入通信 路由拦截 fuseprovn:// 方式')
  const message = JSON.stringify({
    ...params,
    callbackName
  })

  // 判断是否有回调方法
  if (callback && typeof callback == 'function') {
    // 配置全局回调
    setWindowCallback(callbackName, callback, cbType)
  }
  window.location.href = `fuseprovn://${message}`
}

// 通过 postMessage 方式通信
// 目前是 桌面端 mac 版本在使用
function dispatchActionByMacPostMessage({
  params = {},
  callback,
  callbackName,
  cbType = 1 // 0 一直存在，不做修改 ; 1 只会调用一次，执行完删除 ; 2 调用一次，执行完会赋空方法
}) {
  console.info('tip: 进入通信 postMessage 方式')

  // 判断是否有回调方法
  if (callback && typeof callback == 'function') {
    // 配置全局回调
    setWindowCallback(callbackName, callback, cbType)
  }

  // mac 专用处理
  window.webkit.messageHandlers.FusePro.postMessage(JSON.stringify({
    ...params,
    callbackName
  }), '*')
}

// 还是通过 postMessage 方式，不过调用方式有点不一样
// 目前是 flutter 打包的h5包中使用
function dispatchActionByCustomize({
  params = {},
  callback,
  callbackName,
  cbType = 1 // 0 一直存在，不做修改 ; 1 只会调用一次，执行完删除 ; 2 调用一次，执行完会赋空方法
}) {
  console.info('tip: 进入通信 h5 方式')
  if (!window.parent) { return Promise.reject('找不到父级window对象') }

  // 判断是否有回调方法
  if (callback && typeof callback == 'function') {
    // 配置全局回调
    setWindowCallback(callbackName, callback, cbType)
  }

  const message = JSON.stringify({
    ...params,
    callbackName
  })
  window.parent.postMessage(message, '*')
}

/**
 * 设置全局回调
 *
 * @param {String} name 回调方法名称
 * @param {Function} fn 回调方法
 * @param {Number} fnFrequency 回调方法执行后的处理
 */
export function setWindowCallback(name, fn, fnFrequency = 1) {
  window.onFusePro[name] = (result) => {
    fn(typeof result == 'string' ? JSON.parse(result) : result)

    // 执行完后就删除方法 释放内存
    if (fnFrequency == 1) {
      delete window.onFusePro[name]
    } else if (fnFrequency == 2) { // 执行完后就重写为默认处理或空函数
      window.onFusePro[name] = FIXED_CALLBACK[name] ? FIXED_CALLBACK[name] : () => {}
    }
  }

  return name
}

/**
 * 和 Fuse Pro 的通信
 * @param {*} param 配置参数
 * @param {} method: getToken ==> 获取用户token
 * @param {} method: getBaseConfig ==> 获取租户信息
 * @param {} method: getDeviceInfo ==> 获取设备信息
 * @param {} method: getPhoto ==> 上传图片
 * @param {} method: uploadProof ==> 上传支付凭证
 * @param {} method: jumpToProductListPage ==> 跳转到 产品列表
 * @param {} method: jumpToHomePage ==> 跳转到 主页
 * @param {} method: jumpToPolicyListPage ==> 跳转到 订单列表
 * @param {} method: jumpToOrderPaymentPage ==> 跳转到 订单支付
 * @param {} method: jumpToQuoteListPage ==> 跳转到 询价单列表
 * @param {} method: jumpToSignaturePage ==> 跳转到 合同签名页面
 * @param {} method: backNativePage ==> 跳转原生页面
 * @param {} method: onNativeBack ==> 原生头部点击回退的处理
 * @param {} method: actionsOnClick ==> 原生头部右上角操作
 * @param {} method: getTimeStamp ==> 获取服务器时间
 * @param {} method: authOnClick ==> KYC popup
 * @param {} method: couponSelection ==> 跳转couponSelection
 * @param {} method: showTitle ==> 是否显示原生头部 参数： show: 1: 显示  0: 不显示 ; title : 显示头部的值
 * @returns
 */
export const dispatchAction = ({
  type = 'FusePro',
  method = '',
  params = {},
  fixedName,
  communication,
  callback,
  cbType = 1 // 0 一直存在，不做修改 ; 1 只会调用一次，执行完删除 ; 2 调用一次，执行完会赋空方法
}) => {
  if (!type) { return Promise.reject('通信的 type 不正确') }
  if (!method) { return Promise.reject('请确保正确的通信 method') }

  // 公共参数
  // 回调的方法名称
  // 这里做个特殊处理
  // 上传图片方式不支持异步回调，只能通过调用指定回调方法的方式处理
  const isUploadMethod = method == 'getPhoto'
  const callbackName = (fixedName || isUploadMethod) ? method : `${method}${setRandomKey()}`

  // 不直接通信
  // 直接生成全局方法 让 app 直接调用
  if (communication) {
    setWindowCallback(callbackName, callback, cbType)
    return
  }

  // 公共的通信参数
  const commonMessage = {
    method,
    params
  }
  // 最终通信的参数
  const dispatchParams = { type, params: commonMessage, callback, callbackName, cbType }

  // 在 fuse pro 中的逻辑
  if (isApp) { // app
    dispatchActionByFlutter(dispatchParams)
  } else if (isDesktop) { // 桌面端
    if (isMac) { // Mac版本特殊方式
      dispatchActionByMacPostMessage(dispatchParams)
    } else { // 其他桌面端共用方式
      dispatchActionByRouteInterception(dispatchParams)
    }
  } else if (isWeb) { // flutter 打包的h5
    dispatchActionByCustomize(dispatchParams)
  } else {
    console.info(`暂不支持和客户端的通信`)
  }
}

// 这是接受 flutter 打包成h5 模式接收flutter那边的回调处理
window.addEventListener('message', function (e) {
  try {
    console.log('tip: 接受app的 postMessage 信息', e)
    if (typeof e.data == 'object' || e.data == 'undefined') { return }
    const data = JSON.parse(e.data) || {}
    const { method, value } = data
    if (!method) { return }
    if (!window.onFusePro[method]) {
      console.log(`window.onFusePro 下没找到 ${method} 方法`)
      return
    }

    window.onFusePro[method](value)
  } catch (error) {
    console.error(error)
  }
})
